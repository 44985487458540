import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import Banner from '../../../components/features/Banner'
import SEO from '../../../components/common/SEO';
import ReactWOW from 'react-wow'
import FeatureCards from "../../../components/features/FeatureCards"


export const query = graphql`
  query SafetyDataSheets {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
  const [toggler, setToggler] = useState(false)
  const lightboxProps = {
    toggler: toggler,
    setToggler: setToggler,
    sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
  }

  const bannerProps = {
    title: 'Safety Data Sheets',
    subtitle: `Do your workers have to thumb through a massive binder to figure out the procedures, and potential hazards associated with a chemical product? Workhub can make this process way faster and easier. Our Safety Data Sheets component makes it simple to upload, store, and search for Material Safety Data Sheets (MSDS). Eliminate the frustration of keeping all those documents updated and organized and help your workers save time in an emergency by labelling all your containers with a QR code that is easily scanned on mobile devices.  `,
    featureName: 'behaviourObservations',
    rightButtonText: 'Why Certificate Tracking Software?',
    rightButtonSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
    mockupImg: '/features/sds/feature-page-header-sds.png'
  }

  const seo = {
    title: 'Workhub | Free Safety Data Sheet (MSDS/SDS) Management Software Storage',
    description: "Our Safety Data Sheet Management Software makes it easy to store, organize & distribute SDS's. Upload SDS to database & scan container QR codes with mobile device for easy worker access to SDS.",
    image: '/features/sds/social-card.jpg',
    // pathname,
    // article
  }

  const featureIndex = [
    {
      title: "Upload",
      para: "Replace bulky Safety Data Sheet binders by uploading a digital copy from a suppliers’ website. ",
    },
    {
      title: "QR Codes",
      para: "Label your materials with QR codes to allow workers to simply scan for quick SDS lookup. ",
    },
    {
      title: "Notes",
      para: "Add unique notes to SDS to provide further insight for workers. ",
    },
    {
      title: "Safe Handling Instructions",
      para: "Safe handling instructions allow you to print off workplace labels that comply with both WHMIS (Workplace Hazardous Materials Information System) and GHS (Globally Harmonized System) standards. ",
    },
  ]

  return (
    <Layout {...lightboxProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} {...lightboxProps} />

      <main className="feature-main">

        <section className="feature-section">
          <div className="container" >
            <div className="row switch-children">

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="\features\sds\sketch-mockup-SDS-admin.png"
                    alt="Admin Computer"
                  />
                </ReactWOW>

              </div>


              <div className="col-lg-6 feature-text">
                <h3>All in the palm of your hand.</h3>

                <p>Create a new SDS by uploading a PDF of the original safety data sheets provided by the manufacture, along with the preparation date and safe-handling instructions. Our system will automatically generate a unique QR code that you can print on labels and place on the products for easy lookup in the field. You can identify products as Consumer or Hazardous, as well as create section headers which organize the sheets into categories such as: Cleaning, Paint, and Grease & Oil products.</p>
              </div>


            </div>
          </div>
        </section>


        <section className="feature-section">
          <div className="container" >
            <div className="row">


              <div className="col-lg-6 feature-text">

                <h3>Wide Reach.</h3>

                <p>One of the best features of our Safety Data Sheets component is that all your MSDS are uploaded in a central repository and can be viewed from any of your company’s locations. No need to worry about distributing or ensuring your workers have access to the necessary documents. They are all in one spot. You can also customize which locations see the SDS/MSDS entries; if a chemical product is only used in your Tallahassee branch there is no point showing the safety data sheet to the folks in Nantucket. </p>

              </div>

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInRight" delay="0.9s">

                  <img
                    className="feature-img-left"
                    src="\features\sds\sketch-mockup-SDS-admin-list.png"
                    alt="SDS View Admin Computer"
                  />

                </ReactWOW>

              </div>

            </div>
          </div>
        </section>



        <section className="feature-section">
          <div className="container" >
            <div className="row switch-children">

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="\features\sds\sketch-mockup-whistleblower-worker.png"
                    alt="Worker Tablet View"
                  />
                </ReactWOW>

              </div>


              <div className="col-lg-6 feature-text">

                <h3>Damage Control.</h3>

                <p>Your workers can easily access and search for the safety data sheet by product name on the worker dashboard, or they can scan the QR code on the workplace label to bring up all the information relevant to that product. Gone are the days of having to frantically run to the storage cabinet during a spill or emergency. Now your workers can simply scan and access the procedures and protocols on their phone. Shaving off those seconds during a fire, explosion or physical exposure can greatly minimize the severity of damage by promoting better response times.</p>

              </div>
            </div>
          </div>
        </section>

        {/* FEATURE OVERVIEW */}

        <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid">
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>

      </main>
    </Layout>
  )
}